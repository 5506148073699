//import * as VueRouter from 'vue-router';

import Home from './views/Home.vue'
import Error from './views/Error.vue'
import Index from './views/Index.vue'
import Detail from './views/Detail.vue'

const routes = [
  { path: '/home', redirect: '/' },
  { name: 'Home', path: '/', component: Home },
  { name: 'Error', path: '/error', component: Home },
  { name: 'Default', path: '/:primi', component: Index },
  { name: 'Subpage', path: '/:primi/:secondi', component: Detail },
  { name: 'Detail', path: '/:primi/:secondi/:dessert', component: Home },
]

import { createRouter, createWebHistory } from 'vue-router';
import { useScroll } from '@vueuse/core';

const scrollPositions = {};

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    setTimeout(()=>{window.scrollTo({ top: (scrollPositions[to.path] || 0)})}, 200);
  }
});

router.beforeEach((to, from, next) => {
  scrollPositions[from.path] = window.scrollY;
  next();
});
