// stores/counter.js
import { defineStore } from 'pinia';
import yaml from 'js-yaml';
import { cleanUpJSON } from './utils/index.js'
import axios from 'axios';
import For from './KQLSelects.js';
const select = For;

//Store
export const useStore = defineStore('kirby', () => {

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !State */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  const loading = ref(0);
  const site = ref({});
  const rawPage = ref({});
  const rawPages = ref({});
  const api = "/api/cachedKQL"; //api: "/api/query"

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !State with added methods */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  const page = computed(()=>{
    let modifiedPage = rawPage.value;
    //modifiedPage.content.yaml = (field)=> yaml.load(modifiedPage.content[field]);
    return modifiedPage;
  });

  const pages = computed(()=>{
      let modifiedPages = rawPages.value;
      //modifiedPages.byKey = (customKey = 'uid') => {return GetByKey(customKey, rawPages.value)};
      return modifiedPages;
  });

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Helpers */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  const GetByKey = (customKey = 'uid', pagesObject) => {
    let returnedPages = [];
    Object.keys(pagesObject).map((key)=>{returnedPages[pagesObject[key][customKey]] = pagesObject[key]});
    return returnedPages;
  };

  const ArrayOfPageUIDsFromPath = (path = 'home') => {
    path = path.replace(/\//, "");
    let pageDirectories = !path ? ['home'] : path.split('/');
    let language = 'en';
    if(pages[0] == document.documentElement.lang) language = pageDirectories.shift();
    return (pageDirectories.length == 0) ? ['home'] : pageDirectories;
  }

  const KQLQueryStringFromArrayOfParentIDs = (pageDirectories = ['home']) => {
    let queryString = 'page("'+pageDirectories.shift()+'")';
    while (pageDirectories.length) {
      queryString += '.children.find("'+pageDirectories.shift()+'")'
    }
    return queryString;
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Methods: loadPageAndSite */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  //Load pages and site. Usually only once
  const loadPageAndSite = (path = 'home', template) => {
    loading.value += 1;
    const pageUIDs = ArrayOfPageUIDsFromPath(path);
    const queryString = KQLQueryStringFromArrayOfParentIDs(pageUIDs);
    const query = {
      select: {
        site: {
          query: 'site',
          select: select.For.Site,
        },
        pages: {
          query: 'site.children',
          select: select.For.Children
        }
      }
    };
      //Load
    axios.post(api, query).then((response) => {
      if(!response.data.result.pages.length) throw new Error("Kirby API is not loading or does not have pages to load.");
      if(!response.data.result.pages.length) console.error("Kirby API is not loading or does not have pages to load.");
      if(!response.data.result.pages.length) throw new TypeError("Kirby API is not loading or does not have pages to load.");
      loading.value -= 1;
      site.value = cleanUpJSON(response.data.result.site);
      rawPages.value = cleanUpJSON(response.data.result.pages);
    });
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Methods: loadPage*/
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  //Load current page
  const loadPage = (path = 'home', template) => {
    loading.value += 1;
    const pages = ArrayOfPageUIDsFromPath(path);
    const queryString = KQLQueryStringFromArrayOfParentIDs(pages);
    const query = {
      select: {
        page: {
          query: queryString,
          select: select.For[template] || select.For.Default
        }
      }
    }
    //Load
    axios.post(api, query).then((response) => {
      if(!response.data.result.page) throw new Error("Kirby API is not loading or does not have pages to load.");
      loading.value -= 1;
      rawPage.value = cleanUpJSON(response.data.result.page);
    });
  }

  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /* !Return outside */
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  return {loading, site, pages, page, loadPageAndSite, loadPage};
})
